import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {findCatFromOutlet} from 'rev.sdk.js/Utils/CategoryUtil';

function BrowserHeader(props) {
  const [categories] = useOutlet('categories');
  const [articleCategories] = useOutlet('articleCategories');
  const [salesEventCategories] = useOutlet('salesEventCategories');

  const {route, cat} = props;

  let rootCat = null;
  let catObj = null;
  let defaultCatImg = null;

  if (route === '/products') {
    rootCat = categories;
    catObj = findCatFromOutlet('categories', cat);
    defaultCatImg = rootCat.meta_data?.img_url;
  } else if (route === '/articles') {
    rootCat = articleCategories;
    catObj = findCatFromOutlet('articleCategories', cat);
    defaultCatImg = rootCat.meta_data?.img_url;
  } else if (route === '/sales-event') {
    rootCat = salesEventCategories;
    catObj = findCatFromOutlet('salesEventCategories', cat);
    defaultCatImg = rootCat.meta_data?.img_url;
  } else {
    return null;
  }

  const imgUrl = catObj?.img_url || defaultCatImg;

  return (
    <Wrapper
      className="browse-header"
      img={imgUrl}
      css={!imgUrl && 'display: none;'}
    />
  );
}

const Wrapper = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  height: 400px;

  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) => props.css || ''}
`;

export default BrowserHeader;
